import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container pt-5">
          {/* Center the entire row */}
          <div className="row justify-content-center align-items-center mb-4">
            {/* Center text within the column */}
            <div className="col-md-10 text-center">
              {/* Center the logo */}
              <div className="footer-logo mb-4">
                <Link to="/">
                  <img
                    src="assets/logo.png"
                    alt="PandaKombat Logo"
                    className="mx-auto d-block"
                  />
                </Link>
              </div>

              {/* Centered Paragraph */}
              <p className="mb-3" style={{ color: "white" }}>
                A panda is bambooing merrily as it swims freely into the TON
                Ecosystem.
              </p>

              {/* Center the social media links */}
              <div className="d-flex flex-wrap justify-content-center gap-4 mb-lg-5 pb-lg-5 mb-3">
                <a
                  href="https://t.me/PandaKombat_Official_Ann"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  <img src="assets/tg.svg" alt="Telegram" className="me-2" />
                  Telegram
                </a>
                <a
                  href="https://x.com/PandaKombat_io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  <img src="assets/x.svg" alt="Twitter" className="me-2" />
                  Twitter
                </a>
                <a
                  href="https://medium.com/@PandaKombat"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  <img src="assets/md.svg" alt="Medium" className="me-2" />
                  Medium
                </a>
              </div>

              {/* Centered Footer Text */}
              <p className="fw-300" style={{ color: "white" }}>
                © 2024 PandaKombat. All rights reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
