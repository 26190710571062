// src/Pages/Blocked/Blocked.jsx
import React from "react";
import "./Blocked.css"; // Import the CSS file for styling

export default function Blocked() {
  return (
    <div className="blocked-container">
      <h1>This website is not available in your country</h1>
    </div>
  );
}
