import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import Presale from "./Pages/Presale/Presale";
import TokenAllocation from "./Pages/TokenAllocation/TokenAllocation";
import Blocked from "./Pages/Blocked/Blocked";

function App() {
  // Chain state that will be shared between Presale and Header
  const [selectedChain, setSelectedChain] = useState("TON");
  const [presaleAmount, setPresaleAmount] = useState<number>(0);

  return (
    <Router>
      {/* Pass the chain state and setter to both Header and Presale */}
      <Header selectedChain={selectedChain} presaleAmount={presaleAmount} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/blocked" element={<Blocked />} />

        {/* uncommcent for presale */}
        {/* <Route
          path="/presale"
          element={
            <Presale
              selectedChain={selectedChain}
              setSelectedChain={setSelectedChain}
              presaleAmount={presaleAmount}
              setPresaleAmount={setPresaleAmount}
            />
          }
        />
        <Route
          path="/tokenAllocation"
          element={
            <TokenAllocation
              selectedChain={selectedChain}
              setSelectedChain={setSelectedChain}
              presaleAmount={presaleAmount}
              setPresaleAmount={setPresaleAmount}
            />
          }
        /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
