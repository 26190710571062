import React, { useEffect } from "react";
import { WindupChildren, Pace } from "windups";

import Aos from "aos";
import "aos/dist/aos.css";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Banner  */}
      <section
        className="banner"
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-7 col-md-9 pt-lg-5">
              <div
                className="top-text pt-lg-5 pt-100"
                style={{
                  color: "black",
                  textShadow:
                    "2px 2px 0px white, -2px 2px 0px white, 2px -2px 0px white, -2px -2px 0px white",
                  fontSize: "24px", // Applying same font size as the first section
                  fontWeight: "bold", // Applying bold font weight like the first section
                }}
              >
                PandaKombat
              </div>
              <h1
                style={{
                  color: "black",
                  textShadow:
                    "3px 3px 0px white, -3px 3px 0px white, 3px -3px 0px white, -3px -3px 0px white", // Larger text shadow for the h1
                  fontWeight: "bold", // Applying bold font weight
                }}
              >
                Join the saga
              </h1>

              <div className="d-inline-flex flex-md-row flex-column gap-3 my-4 mt-lg-5 justify-content-center">
                <a
                  href="https://t.me/PandaKombat_Official_bot"
                  className="primary-secondary"
                  target={"_blank"}
                >
                  Telegram Mini App (Get Airdrop)
                </a>
                <a
                  href="https://t.me/PandaKombat_Official_ANN"
                  target={"_blank"}
                  className="primary-secondary"
                >
                  Panda Community
                </a>
              </div>
              <div className="mb-4 panda-img">
                <img src="assets/panda.png" alt="" />
              </div>
              <Link to="/presale" className="primary-secondary">
                Presale
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="bg2">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-9 col-lg-8 pt-lg-5 pt-4"
              style={{ marginTop: "-450px" }}
            >
              <h2 className="text-white">Unleash the Warrior</h2>
              <p className="text-white">
                Panda Kombat is the ultimate TON-based battle experience, where
                fierce panda warriors rise to claim glory. This action-packed
                game invites fighters to unlock hidden treasures and dominate
                the arena. Train, fight, and become a legend as you power up and
                earn massive rewards. Join Panda Kombat and conquer the
                battlefield!
              </p>

              <div className="d-flex gap-3 my-4 mt-lg-5">
                <a
                  href="https://medium.com/@PandaKombat"
                  target={"_blank"}
                  className="learn-btn"
                >
                  {" "}
                  Learn More{" "}
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M31.4142 17.4142L24.3281 24.5004C23.9376 24.8909 23.4258 25.0862 22.9139 25.0862C22.402 25.0862 21.8902 24.8909 21.4996 24.5004C20.7186 23.7193 20.7186 22.453 21.4996 21.6719L25.1716 18H2C0.895437 18 0 17.1046 0 16C0 14.8955 0.895437 14 2 14H25.1716L21.4996 10.3282C20.7186 9.54711 20.7186 8.2808 21.4996 7.49974C22.2807 6.71867 23.547 6.71867 24.3281 7.49974L31.4142 14.5859C32.1952 15.3669 32.1952 16.6332 31.4142 17.4142Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg3">
        <div className="container">
          <div className="row">
            <div
              className="col-md-10 col-lg-9 pt-lg-5 pt-4"
              style={{ marginTop: "-20px" }}
            >
              <h2 className="" style={{ color: "white" }}>
                Turn Panda Points into Airdrop!
              </h2>
              <p className="" style={{ color: "white" }}>
                Get ready for the biggest panda aidrop in the TON Ecosystem!
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <h2 className="text-white text-center fs-40">
              <img src="assets/panda-logo.svg" alt="" className="logo-img" /> <br className="d-md-none d-block" /> and their friends
              </h2>
            </div>
          </div>
        </div>
        <div className="mt-lg-5 mt-4">
            <Marquee>
              <div className="partner-box"><img src="assets/p1.png" alt="" /></div>
              <div className="partner-box"><img src="assets/p2.png" alt="" /></div>
              <div className="partner-box"><img src="assets/p3.png" alt="" /></div>
              <div className="partner-box"><img src="assets/p4.png" alt="" /></div>
              <div className="partner-box"><img src="assets/p5.png" alt="" /></div>
              <div className="partner-box"><img src="assets/p1.png" alt="" /></div>

            </Marquee>
          </div>
          <div className="mt-4">
            <Marquee direction="right">
              <div className="partner-box"><img src="assets/p1.png" alt="" /></div>
              <div className="partner-box"><img src="assets/p2.png" alt="" /></div>
              <div className="partner-box"><img src="assets/p3.png" alt="" /></div>
              <div className="partner-box"><img src="assets/p4.png" alt="" /></div>
              <div className="partner-box"><img src="assets/p5.png" alt="" /></div>
              <div className="partner-box"><img src="assets/p1.png" alt="" /></div>
            </Marquee>
          </div>
      </section> */}
    </>
  );
};

export default Home;
