import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { TonConnectButton } from "@tonconnect/ui-react";
import "./Header.css";

interface HeaderProps {
  selectedChain: string; // This will be passed from App
  presaleAmount: number; // This will be passed from App
}

const Header: React.FC<HeaderProps> = ({ selectedChain, presaleAmount }) => {
  const [stickyClass, setStickyClass] = useState("relative");

  // Get the current location
  const location = useLocation();

  // Function to toggle the mobile menu
  const toggleMobileMenu = () => {
    document.body.classList.toggle("home-sm");
  };

  // Function to handle navbar stickiness on scroll
  const stickNavbar = () => {
    if (typeof window !== "undefined") {
      const windowHeight = window.scrollY;
      setStickyClass(windowHeight > 50 ? "bg-black" : "bg-transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  // If the current path is "/blocked", return only Logo
  if (location.pathname === "/blocked") {
    return (
      <nav className={`navbar navbar-expand-lg transition-all ${stickyClass}`}>
        <div className="container">
          {/* Logo */}
          <img src="assets/logo.png" alt="Logo" />
        </div>
      </nav>
    );
  }

  return (
    <nav className={`navbar navbar-expand-lg transition-all ${stickyClass}`}>
      <div className="container">
        {/* Logo */}
        <Link to="/" className="navbar-brand" onClick={toggleMobileMenu}>
          <img src="assets/logo.png" alt="Logo" />
        </Link>

        {/* Mobile menu toggle button */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMobileMenu}
        >
          <span className="iconify" data-icon="humbleicons:bars"></span>
        </button>

        {/* Navigation links */}
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {/* Close button for mobile menu */}
          <div className="d-md-none d-block text-end p-4">
            <button className="cross-btn" onClick={toggleMobileMenu}>
              <span className="iconify" data-icon="akar-icons:cross"></span>
            </button>
          </div>

          {/* Navbar links */}
          <ul className="navbar-nav mx-auto align-items-center px-4 px-lg-0">
            <li className="nav-item">
              {/* Home link */}
              <NavLink
                to="/"
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                onClick={toggleMobileMenu}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              {/* Presale link */}
              <NavLink
                to="/presale"
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                onClick={toggleMobileMenu}
              >
                Presale
              </NavLink>
            </li>
            <li className="nav-item">
              {/* Allocation link */}
              <NavLink
                to="/tokenAllocation"
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                onClick={toggleMobileMenu}
              >
                Allocation
              </NavLink>
            </li>
          </ul>

          {/* Connect Wallet buttons */}
          <div className="d-lg-flex gap-3 px-4 px-lg-0">
            {selectedChain === "TON" ? (
              <div className="connect-button">
                <TonConnectButton />
              </div>
            ) : (
              <div className="connect-button">
                <ConnectButton />
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
